/**
* Responsive tables styles
*/

.respontable {
  position: relative;

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    vertical-align: middle;
  }
  /* Global table elements colours */
  .text-green {
    color: $success-color-dark;
  }
  .text-red {
    color: $danger-color-dark;
  }
  .td-icon {
    text-align: center;
  }
  /* Loading animation */
  &--loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-size: 260px 5px;
    background-color: $table-progress-background-color;
    background-image: linear-gradient(
      -18deg,
      transparent 0%,
      transparent 8%,
      $table-progress-bar-color 60%,
      $table-progress-bar-color 64%,
      transparent 86%
    );
    transition: height 200ms ease;
    animation: table-loading 0.5s linear infinite;
    display: none;

    &.is-active {
      height: 3px;
      display: block;
    }
  }

  table {
    width: 100%;
    border: $table-border;
    margin-left: auto;
    margin-right: auto;
  }

  thead,
  tfoot {
    display: none;

    td {
      background: $table-background-color;
      font-weight: $table-header-font-weight;

      &.sort {
        color: $table-sort-icon-color;
        cursor: pointer;
        transition: color 200ms ease;

        &:after {
          position: relative;
          left: 3px;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          content: "\f0dc"; /* Sort up & down arrow*/
        }

        &:hover {
          color: $table-sort-icon-color-hover;
        }

        &--asc:after {
          content: "\f0de"; /* Sort up arrow*/
        }

        &--desc:after {
          content: "\f0dd"; /* Sort down arrow*/
        }
      }

      &:hover {
        a {
          color: $table-row-hover-highlight-color !important;
        }
      }

      a:before {
        content: "\f046";
        font: normal normal normal 14px/1 FontAwesome;
        width: 15px;
        height: 15px;
        top: 8.5px;
        left: 5px;
        margin-right: 4px;
      }
    }
  }

  caption {
    background: $table-striped-background-color;
    border-radius: 2px;
    padding: 8px 12px;
    caption-side: bottom;
  }

  tbody {
    td {
      background: $table-striped-background-color;
      min-height: 43px;
      box-sizing: border-box;
    }

    .text-status {
      color: inherit;
      &-display_inherit {
        display: inherit;
      }
    }
  }

  td {
    display: none;
    text-align: right;
    border: 0;
    border-radius: 2px;
    padding: 8px 12px;
    line-height: 2;
    color: $table-text-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    /* td that is only shoed on mobile sizes */
    &.responsive-title {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      &:before {
        word-break: break-word;
        flex: 1 1 100%;
      }
      .responsive-title__info {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
      }
      .responsive-title__link {
        position: absolute;
        top: 8px;
        right: 12px;
        width: 20px;
        height: 20px;
        &:before {
          content: "\f08e";
          font: normal normal normal 14px/1 FontAwesome;
          padding: 2px;
        }
      }
    }

    /* Responsive label */
    &:before {
      content: attr(responsive-label);
      float: left;
      text-align: left;
      color: $table-text-color;
      word-break: break-word;
    }
  }

  tr {
    display: block;
    border: 0;
    overflow: hidden;
    margin-bottom: 3px;

    &.is-selected {
      margin-bottom: 6px;
      margin-top: 5px;
      border: 1px solid $table-responsive-head-background-color;
      background: $table-responsive-head-background-color;
      border-radius: 3px;

      td {
        display: block;
        border-radius: 0;

        /* td that is only showed on mobile sizes */
        &.responsive-title {
          border-radius: 2px 2px 0 0;
          background: $table-responsive-head-background-color;
          color: $table-responsive-head-text-color;

          &:before {
            color: $table-responsive-head-text-color;
            display: flex;
          }

          /* collapse arrow */
          &:after {
            border-top: 0 none;
            border-bottom: 7px solid $table-responsive-head-text-color;
          }

          .responsive-title__info {
            display: none;
          }
        }

        &:last-child {
          border-radius: 0 0 2px 2px;
        }
      }
    }
  }

  /* Links and pseudo links */
  a,
  .as-link {
    color: $table-row-hover-highlight-color;
    cursor: pointer;
  }

  /* FontAwesome icons */
  i {
    display: inline-block;
    position: relative;
    height: 16px;
    width: 16px;
    transition: opacity 200ms ease;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:before {
      position: absolute;
      top: 1px;
      font-size: 20px;
      left: 0px;
    }

    &.fa-edit,
    &.fa-plus {
      margin-left: 10px;
    }
    &.iconsend {
      font-size: 15px;
      margin-top: 10px;
    }
    &.iconsend-disable {
      font-size: 15px;
      margin-top: 10px;
      cursor: not-allowed;
      opacity: 0.5;
      
    }
  }

  /* Select row checkbox */
  input[type="checkbox"] {
    visibility: hidden;
    width: 16px;
    height: 18px;
    vertical-align: middle;

    &::before {    
      cursor: pointer;
      display: block;
      visibility: visible;
      content: "\f096";  /* Font Awesome 4 unchecked icon */
      font-family: FontAwesome;
      font-size: 19px; 
      width: 17px;
      height: 17px;
      line-height: 15px;
      color: $table-checkbox-color;
    }

    &:checked::before {
      content: "\f046";
    }

    &:hover::before {
      opacity: 0.7;
    }
  }

  /* Table filters */
  &__filters {
    margin-bottom: 6px;
    display: flex;

    input[type="text"],
    select {
      flex: 1 1 100%;
      margin-right: 2px;
      background: $table-background-color;
      border: 1px solid $table-background-color;
      color: $table-text-color;
      border-radius: 2px;
      min-height: 24px;
      max-width: 240px;
      padding: 5px;
    }
  }

  /* Row controls (icons) */
  &__controls {
    i + i {
      margin-left: 12px;
    }
  }
}

@keyframes table-loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -260px 0;
  }
}

/*@media only screen and (max-width: 1500px) {
  .respontable {
    font-size: 5px;

    td {
      padding: 2px 2px;
      font-size: 6.5px;
    }

    input[type='checkbox'] {
      &:before {
        cursor: pointer;
        font-size: 10px;
      }
    }

    i {
      &:before {
        font-size: 10px;
      }
    }
  }
}*/

@media only screen and (min-width: 560px) {
  .respontable {
    td {
      &.responsive-title {
        flex-wrap: nowrap;
        padding-right: 38px;
        &:before {
          float: none;
          flex: 0 1 50%;
        }
        .responsive-title__info {
          flex: 0 1 50%;
          > div {
            display: inline-block;
            vertical-align: top;
            text-align: left;
            box-sizing: border-box;
            width: calc(50% - 10px);
            padding-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .responsive-title__link {
          top: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 995px) {
  .respontable {
    a,
    .as-link {
      color: $table-text-color;
    }

    table {
      border-radius: 2px;
      overflow-x: hidden;
    }

    tr {
      margin-bottom: 0;
      display: table-row;
    }

    td {
      border-radius: 0;
      display: table-cell;
      text-align: left;
      line-height: 2.5;

      &.responsive-title {
        display: none !important;
      }

      &.align-right {
        text-align: right !important;
      }

      &.align-center {
        text-align: center !important;
      }

      &.ellipsis-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      &.big-size-column {
        min-width: 150px;
      }

      &.medium-size-column {
        min-width: 80px;
      }

      &.small-size-column {
        min-width: 20px;
      }
    }

    thead,
    tfoot {
      display: table-row-group;
    }

    caption {
      border-radius: 0;
    }

    tbody {
      position: relative;
      top: 1px;
      td {
        display: table-cell;
        text-align: left;
        background: transparent;
        min-height: unset;

        &:before {
          display: none;
        }

        // &:nth-child(odd) {
        //   /* background: inherit; */
        // }
      }

      tr {
        box-shadow: none;
        background: $table-striped-background-color;

        a:after {
          // content: '\f08e';
          font: normal normal normal 10px/1 FontAwesome;
          width: 11px;
          height: 10px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 2px;
          opacity: 0;
          transition: opacity 250ms ease;
        }

        &:last-child td {
          border-bottom: none;
        }

        &.is-selected {
          box-shadow: none;
          margin: 0;
          background: transparent;
          td {
            display: table-cell;
          }
        }

        outline: 1px solid transparent;
        transition: outline 250ms ease;

        &:hover {
          background-color: $table-row-hover-color;

          a {
            color: $table-row-hover-highlight-color !important;
          }
          a:after {
            opacity: 1;
          }
          i {
            color: $table-row-hover-highlight-color;
          }
          .text-green > i {
            color: $success-color-dark !important;
          }
          .text-red > i {
            color: $danger-color-dark !important;
          }
          outline-color: $table-row-hover-outline-color;
        }
      }
    }
    .text-status:before {
      top: 10px;
    }
  }
}

@media only screen and (min-width: 995px) {
  .hide-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 994px) {
  .hide-mobile {
    display: none !important;
  }
}

@mixin ellipsis-clamp($num-lines) {
  display: -webkit-box;
  -webkit-line-clamp: $num-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-clamp-1 {
  @include ellipsis-clamp(1);
}
