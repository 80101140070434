/**
* Main styles file
* Please use this file only to import stylesheets
*/
@import "reset";
@import "mixins";
@import "fonts";
@import "main.scss";
@import "themeloader.scss";
@import "material-forms.scss";
@import "material-icons.scss";
@import "~font-awesome/scss/font-awesome.scss";
@import "components/alerts-styles.scss";
@import "components/respontable-styles.scss";
@import "components/hamburger-styles.scss";
@import "components/page-head-styles.scss";
@import "components/page-controls-styles.scss";
@import "components/pagination-styles.scss";
@import "components/search-box-styles.scss";
@import "components/modal-styles.scss";
@import "components/chart-styles.scss";
@import "components/tooltip-styles";
@import "components/status-styles";
@import "components/docview-styles.scss";
@import "components/validation-related-docs-styles.scss";
@import "components/validation-collapsible-styles.scss";
@import "components/datamodel-validations-styles.scss";
@import "components/reviewers.scss";
@import "components/documents-styles.scss";
@import "components/timeline-styles.scss";
@import "components/document-fields-list-styles.scss";
@import "components/document-dashboard-styles.scss";
@import "components/filters-styles.scss";
@import "~@circlon/angular-tree-component/css/angular-tree-component.css";
@import "components/tree-styles.scss";
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-spreadsheet/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
/* Please use this file only to import stylesheets */
